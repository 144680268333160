import React from 'react'
import GoogleMap from './index'
import { Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

export default function Locations() {

    return (
        <div className="map-container">
            <GoogleMap />
            <div className="contact-modal white">
                <Col className="contact-direct">
                    <h2 className="gold">Contact Us Direct</h2>
                    <p><a href="tel:+16472031011"><FontAwesomeIcon icon={faPhone} /> 647-203-1011</a> <span>|</span> <a href="mailto:colin@bravo1.ca"><FontAwesomeIcon icon={faEnvelope} /> colin@bravo1.ca</a></p>
                    <ul className="social-icon-wrapper">
                        <li><a href="https://www.facebook.com/bravo1detailing/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a href="https://twitter.com/Bravo1Detailing" rel="noreferrer" aria-label="Twitter Link" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        <li><a href="https://www.instagram.com/bravo1detailing/" rel="noreferrer" aria-label="Instagram Link" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                    </ul>
                    <p><small>** Travel Charges Applicable - depending on your location</small></p>
                    <p><small>Copyright &copy; Bravo-1 Detailing 2021</small></p>
                </Col>
            </div>
        </div>
    )
}
