import React from 'react'
import Cookie from './cookie'
import Location from "../map/locations"

export default function Footer() {

    return (
        <>
            <Location />
            <Cookie />
        </>
    )
}
