import React from 'react'
import { Link } from 'gatsby'
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import Scrollspy from 'react-scrollspy'

import 'bootstrap/dist/css/bootstrap.min.css'

const Header = () => (
	<Navbar expand="lg" fixed="top" id="menu-wrap" className="menu-back cbp-af-header" variant="dark">
		<Container>
		<Navbar.Brand href="/#home" className="logo"></Navbar.Brand>
		<Navbar.Toggle aria-controls="navbarResponsive" />
		<Navbar.Collapse id="navbarResponsive">
			<Scrollspy items={ ['home', 'about'] } currentClassName="active">
				<Nav.Item as="li">
					<Link to="/#home">Home</Link>
				</Nav.Item>
				<Nav.Item as="li">
					<Link to="/#about">About</Link>
				</Nav.Item>
				<Nav.Item as="li">
					<NavDropdown title="Services" id="basic-nav-dropdown">
						<NavDropdown.Item href="/services">All Services</NavDropdown.Item>
						<NavDropdown.Item href="/detailing">Detailing Packages</NavDropdown.Item>
						<NavDropdown.Item href="/paint-enhancement">Paint Enhancement</NavDropdown.Item>
						<NavDropdown.Item href="/coating-wax">Coating &amp; Wax</NavDropdown.Item>
					</NavDropdown>
				</Nav.Item>
				<Nav.Item as="li">
					<Link to="/contact">Contact</Link>
				</Nav.Item>
			</Scrollspy> 
		</Navbar.Collapse>
		</Container>
	</Navbar>
)
  
export default Header